import { styled } from "@/styled"

export const SliderStyled = styled("div", {
  width: "100%",
  "@media (min-width: 640px)": {
    width: "calc(100% - 316px)",
  },
  ".slick-slider": {
    ".slick-arrow": {
      width: "initial",
      color: "rgb(67 56 202 / 1)",
      fontSize: "inherit",
      zIndex: 999,
      fontWeight: "bold",
      bottom: "17px",
      top: "initial",
      "&.slick-prev": {
        left: 14,
      },
      "&.slick-next": {
        right: 14,
      },
      "&:before": {
        display: "none",
      },
    },
  },
})
