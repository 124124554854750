import React, { useEffect, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { SliderStyled } from "./styled"
import Slider from "react-slick"
import clsx from "clsx"
import sessionCheck from "../../services/session-check"
import { generateToken } from "../../services/checkout"
import { eventDetail } from "../../services/event-service"
import { removeArgumentsFromDocument } from "@apollo/client/utilities"
import { checkoutShopify } from "../../services/checkout"

export const ArtworkItem = ({ settings, items, id_event, referral }) => {
  const [events, setEvents] = useState([])

  // console.log("evnts", events)

  // get value from env

  useEffect(() => {
    eventDetail("borneo-fc", id_event).then(res => {
      setEvents(res?.props?.data?.tickets)
    })
  }, [])

  const handleClickLoket = async widgetUrl => {
    try {
      const dataToken = await generateToken()
      const redirectUrl = `${widgetUrl}?white_label=true&prefill_data=${dataToken?.token}&ref=${dataToken.id}#${referral}`
      window.open(redirectUrl, "_self").focus()
    } catch (error) {
      return console.error("terjadi kesalahan: ", error)
    }
  }

  const handleClickShopify = async shopifyVariant => {
    try {
      const dataToken = await generateToken()
      const directiCheckoutPayload = {
        payloads: [
          {
            variant_id: shopifyVariant,
            quantity: 1,
          },
        ],
        additional: [
          {
            referral_code: "bandung",
          },
        ],
      }
      checkoutShopify(directiCheckoutPayload)
    } catch (error) {
      throw error
    }
  }

  return (
    <div className="sm:flex rounded-2xl overflow-hidden">
      <div className="sm:w-[316px] flex-none bg-red-700 text-white p-6">
        <div className="w-[57px] h-[57px] rounded-full bg-gradient-to-r from-green-400 to-blue-500"></div>
        <h3 className="text-2xl font-bold mt-4 ">{items.event_name}</h3>
        <p className="mt-4 line-clamp-6">{items.custom_description}</p>
      </div>
      <SliderStyled className="flex-none ml-auto bg-gray-100 px-2">
        <Slider {...settings}>
          {events &&
            events.map((tickets, i) => (
              <div key={i}>
                <div
                  className={clsx(
                    "cursor-pointer transition ease-in-out hover:scale-105 h-[454px] pt-4 pb-96 px-2",
                    {
                      // status 2, 3, 4, 5, 6 is sold out or stock not
                      "opacity-60 hover:scale-100 cursor-default": [
                        2, 3, 4, 5, 6,
                      ].includes(tickets?.status_ticket),
                    }
                  )}
                  onClick={() => {
                    if (![2, 3, 4, 5, 6].includes(tickets?.status_ticket)) {
                      tickets?.shopify_variant_id == null
                        ? handleClickLoket(tickets?.group?.widget_url)
                        : handleClickShopify(tickets?.shopify_variant_id)
                    }
                  }}
                >
                  <img
                    src={tickets.ticket_sample_image}
                    className="w-full h-[280px] object-cover rounded-xl"
                    alt=""
                  />
                  <div className="pb-6 ml-2">
                    <h4 className="font-bold line-clamp-1 mt-4 mb-2 text-base lg:text-xl">
                      {tickets.ticket_type}
                    </h4>
                    <h4 className="max-w-max rounded border border-gray-400 bg-gray-200 px-3 py-2 font-semibold">
                      Rp {(tickets.price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.00','')}
                    </h4>
                  </div>
                </div>
              </div>
            ))}
        </Slider>
      </SliderStyled>
    </div>
  )
}
