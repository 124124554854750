import React, { useEffect, useState } from "react"
import { RightMenu } from "./styled"
import { Link } from "react-scroll"
import { Link as GatsbyLink } from "gatsby"
import { persijaData } from "../../data/constant"
import { removeCookie, getCookie } from "typescript-cookie"
import faviconLogo from "../../images/borneo-white-logo.png"
// import axiosConfig from "../../services/axios-config"

export const Navigation = () => {
  const [isLogin, setIsLogin] = useState(false)

  useEffect(() => {
    if (getCookie("access_token")) {
      setIsLogin(true)
    }
  }, [])

  // handle logout
  const handleLogout = () => {
    removeCookie("access_token")
    setIsLogin(false)
    if (typeof window !== "undefined") {
      window.location.href = "/"
    }
  }

  const [show, setShow] = useState(false)
  return (
    <nav className="bg-black z-50">
      <div className="container">
        <div className="flex justify-between items-center h-[80px]">
          <div>
            <div
              className="
            max-w-[150px]"
            >
              <img src={faviconLogo} alt="" />
            </div>
          </div>
          <div className="md:hidden">
            <button onClick={() => setShow(!show)}>
              {show ? (
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.4853 4.34313C19.2664 3.56205 19.2664 2.29578 18.4853 1.5147C17.7042 0.73362 16.4379 0.73362 15.6569 1.5147L10 7.17155L4.34315 1.5147C3.56207 0.73362 2.2958 0.73362 1.51472 1.5147C0.733639 2.29578 0.733639 3.56205 1.51472 4.34313L7.17157 9.99998L1.51472 15.6568C0.733639 16.4379 0.733639 17.7042 1.51472 18.4853C2.2958 19.2663 3.56207 19.2663 4.34315 18.4853L10 12.8284L15.6569 18.4853C16.4379 19.2663 17.7042 19.2663 18.4853 18.4853C19.2664 17.7042 19.2664 16.4379 18.4853 15.6568L12.8284 9.99998L18.4853 4.34313Z"
                    fill="#e53935"
                  />
                </svg>
              ) : (
                <svg
                  width="32"
                  height="20"
                  viewBox="0 0 32 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="32" height="4" rx="2" fill="#e53935" />
                  <rect
                    x="8"
                    y="8"
                    width="24"
                    height="4"
                    rx="2"
                    fill="#e53935"
                  />
                  <rect
                    x="16"
                    y="16"
                    width="16"
                    height="4"
                    rx="2"
                    fill="#e53935"
                  />
                </svg>
              )}
            </button>
          </div>
          <div
            className={`absolute md:static top-20 md:flex md:space-x-4 space-y-4 left-0 md:space-y-0 items-center border-b md:border-b-0 border-r-gray-200 w-full md:w-auto pb-8 md:pb-0 bg-black z-30 ${
              show ? "block" : "hidden"
            }`}
          >
            <RightMenu
              className={`md:flex md:space-x-4 max-w-[1200px] px-6 space-y-4 md:space-y-0 items-center md:w-auto  bg-black z-30 ${
                show ? "block" : "hidden"
              }`}
            >
              <li>
                <Link
                  className="hover:bg-gray-800 text-white cursor-pointer rounded-lg py-2 px-4 transition"
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  Tentang Borneo NFT
                </Link>
              </li>
              <li>
                <GatsbyLink to="https://bolafy.com">
                  <a className="hover:bg-gray-800 text-white rounded-lg py-2 px-4 transition">
                    Bolafy
                  </a>
                </GatsbyLink>
              </li>
              <li>
                <a href="http://bolafy.com/collections">
                  <button
                    className="
                    hover:bg-gray-800 text-white
                    cursor-pointer
                    rounded-lg
                    px-4
                    transition
                    "
                  >
                    Koleksi
                  </button>
                </a>
              </li>
              <li>
                {isLogin ? (
                  <button
                    onClick={() => {
                      handleLogout()
                    }}
                    className="bg-red-700 hover:bg-gray-800 transition text-white rounded-lg py-2 px-6"
                  >
                    Keluar
                  </button>
                ) : (
                  <a href="/login">
                    <button className="bg-red-400 hover:bg-gray-800 transition text-white rounded-lg py-2 px-6">
                      Masuk
                    </button>
                  </a>
                )}
              </li>
            </RightMenu>
          </div>
        </div>
      </div>
    </nav>
  )
}
