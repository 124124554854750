import React from "react"
import SEO from "@/components/seo"
import { Navigation } from "@/components/navigation"
import { Banner } from "@/components/banner"
import { AboutUs } from "@/components/about-us"
import { Collection } from "@/components/collection"
import { Footer } from "@/components/footer"
import { Artworks } from "@/components/artworks"
import { Timeline } from "@/components/timeline/timeline"
import { Script } from "gatsby"

const Home: React.FC = () => {
  return (
    <>
      <SEO />
      <Navigation />
      <Banner />
      <Artworks />
      <AboutUs />
      <Footer />
      <Script
        // src={process.env.NEXT_PUBLIC_MIDTRANS_SNAP_URL}
        src="https://app.midtrans.com/snap/snap.js"
        data-client-key="Mid-client-tpXN5_YSslU6EZKc"
      />
    </>
  )
}

export default Home
