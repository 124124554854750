import axiosConfig from "./axios-config"

const generateToken = async () => {
  try {
    const response = await axiosConfig.get(`/locket/generate-widget-token`)
    return response?.data?.data
  } catch (error) {
    throw error
  }
}

const checkoutShopify = async data => {
  try {
    const response = await axiosConfig.post(`/checkout`, data)
    console.log("fandika response ", response)
    window.snap.pay(response?.data?.data?.token)
  } catch (error) {
    throw error
  }
}

export { generateToken, checkoutShopify }
