import React, { useEffect, useState } from "react"
import { BannerStyled } from "./styled"
import { Link } from "react-scroll"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTelegram } from "@fortawesome/free-brands-svg-icons"
import moment from "moment"

export const Banner = () => {
  return (
    <BannerStyled>
      <div className="container bg-orange-700 w-screen">
        <div className="md:flex md:h-[600px] items-center">
          <div className="sm:text-center text-left w-full md:w-1/2 pt-16 md:pt-0">
            <div>
              <h1 className="text-5xl text-white lg:text-6xl xl:text-7xl font-bold">
                Borneo FC
              </h1>
              {/* <h1 className="text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-bold">
                untuk
              </h1> */}
              <h1 className="text-5xl text-white lg:text-6xl xl:text-7xl font-bold">
                NFT
              </h1>
              <div className="flex space-x-6 sm:justify-center items-center w-full sm:w-auto">
                <div className="my-10">
                  <Link
                    className="hover:bg-gray-100 cursor-pointer rounded-lg transition"
                    to="artworks"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                  >
                    <button className="bg-red-400 hover:bg-gray-700 transition text-white py-4 px-6 rounded-lg">
                      Dapatkan NFT Anda!
                    </button>
                  </Link>
                  {/* <a href="https://bolafy.com/fanbase/persija"></a> */}
                </div>
                <div>
                  <a href="https://bit.ly/TelegramBolafy">
                    <button className="bg-sky-500 border-2 border-sky-500	 hover:bg-gray-700 hover:border-gray-700 text-white transition  py-4 px-6 rounded-lg">
                      <FontAwesomeIcon icon={faTelegram} />
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-1/2 mb-10 w-50% border-8 border-black ">
            <StaticImage
              className="w-50% h-full object-contain "
              placeholder="blurred"
              src="../../images/banner-img.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </BannerStyled>
  )
}
