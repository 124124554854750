import axios from "axios"

export const eventDetail = async (slug: string, id_event: number) => {
  try {
    const { data } = await axios.get(
      `https://api.tiketnft.com/moflip-event?organization_slug=${slug}&event_id=${id_event}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )

    return {
      props: {
        data: data?.data || data,
        error: false,
        message: "",
      },
      revalidate: 5 * 60,
    }
  } catch (error) {
    return {
      props: {
        data: null,
        error: true,
        message: error.message,
      },
    }
  }
}

export const fanbaseDetail = async (slug: string) => {
  try {
    const { data } = await axios.get(
      `https://api.tiketnft.com/organization/list?slug=${slug}&flag=bolafy`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )

    return {
      props: {
        data: data?.data || data,
        error: false,
        message: "",
      },
      revalidate: 5 * 60,
    }
  } catch (error) {
    return {
      props: {
        data: null,
        error: true,
        message: error.message,
      },
    }
  }
}

//  try {
//     const { data } = await axios.get(
//       `${process.env.NEXT_PUBLIC_BACKEND_URL}/moflip-event?organization_slug=${liga}&event_id=${eventId}`,
//       {
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       }
//     )

//     return {
//       props: {
//         data: data?.data || data,
//         error: false,
//         message: '',
//       },
//       revalidate: 5 * 60,
//     }
//   } catch (error) {
//     return {
//       props: {
//         data: null,
//         error: true,
//         message: error.message,
//       },
//     }
//   }
// }
