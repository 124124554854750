import React, { useCallback, useEffect } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { ArtworkItem } from "./artwork-item"
import { fanbaseData } from "../../data/constant"
import { fanbaseDetail } from "../../services/event-service"
import { useState } from "react"

export const Artworks = () => {
  const [organizations, setOrganizations] = useState([])
  const [referral, setReferral] = useState("")

  console.log("ref", referral)

  useEffect(() => {
    fanbaseDetail("borneo-fc").then(res => {
      setOrganizations(res.props.data.events)
    })
  }, [])

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    adaptiveHeight: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerPadding: 20,
        },
      },
      {
        breakpoint: 912,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: 20,
          centerMode: true,
        },
      },
    ],
    prevArrow: (
      <div>
        <div className="flex items-center space-x-4">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#e53935"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
          </div>
          <div className="text-[#e53935] space-x-0">Previous</div>
        </div>
      </div>
    ),
    nextArrow: (
      <div>
        <div className="flex text-[#e53935] items-center space-x-4">
          <div>Next</div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#e53935"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              />
            </svg>
          </div>
        </div>
      </div>
    ),
  }
  return (
    <div className="py-14" id="artworks">
      <div className="container">
        <div>
          <div className="text-center max-w-xl mx-auto">
            <h2 className="text-4xl font-bold"> #CaraBaruDukungKlub </h2>
            <p className="text-lg mt-6 text-gray-500">
              Yuk, ambil andil dalam #CaraBaruDukungKlub!
               Koleksi NFT Borneo sekarang dan menangkan hadiah 
               spesial seperti backstage tour, jersey tanda-tangan, GoPay, Meet and Greet, 
               dan masih banyak lagi!
            </p>
          </div>
        </div>

        {/* <div>
          <div className="mx-auto bg-gray-200 text-center rounded-lg p-2">
            <div className="mt-12">
              <p>This is referral</p>
            </div>
            <div>
              <input
                type="text"
                name="referral"
                id=""
                onChange={val => setReferral(val.target.value)}
              />
            </div>
          </div>
        </div> */}

        <ul className="mt-12 space-y-14">
          {organizations.map((item, i) => (
            <li key={i}>
              {/* ticket not show when is ended */}
              {item.end_date > new Date().toISOString() && (
                <ArtworkItem
                  items={item}
                  settings={settings}
                  id_event={item.id}
                  referral={referral}
                />
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
